/* eslint-disable */
// Shadowing handleSignUpSuccess.js
import { navigate } from "gatsby"
import { firestore } from "gatsby-theme-firebase"
import Cookie from "js-cookie"

export default async ({ user, loginRedirectPath, setErrorMessage }) => {
  // console.log('signUpSuccess');
  const isCart = Cookie.get("selectedOffer")
  try {
    await addUserToDatabase(user)
  } catch (error) {
    setErrorMessage(error.message)
  }
  if (isCart) {
    console.log("To cart!")
    navigate("/app/cart", { replace: true })
  } else {
    navigate(loginRedirectPath, { replace: true })
  }
}

const addUserToDatabase = async user => {
  try {
    await firestore.collection("users").doc(user.uid).set({
      email: user.email,
      phone: user.phoneNumber,
      name: user.displayName,
    })
  } catch (error) {
    console.log(error)
  }
}
