/* eslint-disable */
import { navigate } from "gatsby"
import Cookie from "js-cookie"
import { firestore } from "gatsby-theme-firebase"

export default async ({ user, loginRedirectPath, setErrorMessage }) => {
  const isCart = Cookie.get("selectedOffer")
  console.debug('logInSuccess');
  console.debug('isCart?', isCart);
  try {
    await addUserToDatabase(user.user)
  } catch (ex) {
    console.log(ex)
    setErrorMessage(ex)
  }
  if (isCart) {
    console.debug("To cart!")
    navigate("/app/cart", { replace: true })
  } else {
    navigate(loginRedirectPath, { replace: true })
  }
}

const addUserToDatabase = async user => {
  try {
    let oldUser = await firestore.collection("users").doc(user.uid).get()
    if (!oldUser.data()) {
      await firestore.collection("users").doc(user.uid).set({
        email: user.email,
        phone: user.phoneNumber,
        name: user.displayName,
      })
    }
  } catch (error) {
    console.debug(error)
  }
}
